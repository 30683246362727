<template>
  <b-col cols="12">
    <div class="d-inline-flex align-items-center w-100">
      <b-form-group
          class="mb-0"
      >
        <label class="d-inline-block text-sm-left mr-50">{{ $i18n.t('table.perPage') }}</label>
        <b-form-select
            id="perPageSelect"
            v-model="pPage"
            size="sm"
            :options="pageOptions"
            class="w-50"
            @change="handlePerPageChange"
        />
      </b-form-group>
      <label class="d-inline-block text-sm-left pl-1 m-0"> | {{ $i18n.t('table.Show') }} {{ viewRows }} {{ $i18n.t('table.of') }} {{ totalRows }} {{ $i18n.t('table.records') }}</label>
      <b-pagination
          v-model="cPage"
          :total-rows="totalRows"
          :per-page="pPage"
          class="my-0 ml-auto"
      />
    </div>
  </b-col>
</template>

<script>

export default {
  props: {
    totalRows: {
      type: Number,
      required: true,
    },
    viewRows: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
  },
  data(){
    return {
      pageOptions: [25, 50, 75, 100],
      cPage: 1,
      pPage: 25,
    }
  },
  watch: {
    cPage(newVal){
      this.$emit(`update:currentPage`, newVal)
    },
    pPage(newVal){
      this.$emit(`update:perPage`, newVal)
    },
    currentPage(newVal){
      this.cPage = newVal
    }
  },
  methods: {
    handlePerPageChange(){
      this.$emit('change-per-page')
    },
  }
}
</script>
