<template>
  <b-col
      md="6"
      sm="4"
      class="my-1"
  >
    <div class="d-flex align-items-center">
      <b-dropdown
          id="export"
          :disabled="isLoading"
          variant="primary"
          :text="!isLoading ? $i18n.t('reports.table.export.btn'): $i18n.t('reports.table.export.loading')"
      >
        <b-dropdown-item @click="exportAs('csv')">
          {{ $i18n.t('reports.table.export.format.csv') }}
        </b-dropdown-item>
        <b-dropdown-item @click="exportAs('xlsx')">
          {{ $i18n.t('reports.table.export.format.xlsx') }}
        </b-dropdown-item>
      </b-dropdown>
      <div
          v-if="exportOption"
          class="ml-3 d-flex align-items-center"
      >
        <b-form-checkbox
            v-model="isExportAll"
            name="checkbox-1"
        >
          {{ $i18n.t('reports.table.export.all') }}
        </b-form-checkbox>
      </div>
      <div
          v-if="exportWithImageOption"
          class="ml-3 d-flex align-items-center"
      >
        <b-form-checkbox
            v-model="isExportWithImage"
            name="checkbox-1"
        >
          {{ $i18n.t('reports.table.export.export_image') }}
        </b-form-checkbox>
      </div>
    </div>
  </b-col>
</template>

<script>
import ReportDataServiceFactory from "@/views/reports/services/ReportDataServiceFactory";

export default {
  props: {
    filter: {
      type: Object,
      default: null,
    },
    exportOption: {
      type: Boolean,
      default: false,
    },
    exportWithImageOption: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      isLoading: false,
      reportDataService: ReportDataServiceFactory.create(),
      exportFormat: "xls",
      isExportAll: false,
      isExportWithImage: true,
    }
  },
  computed: {
    fileName(){
      if(this.$route.params.sku) {
        return `${this.$route.params.sku}.${this.exportFormat}`
      }
      return `${this.$i18n.t(`reports.names.${this.$route.name}`)}.${this.exportFormat}`
    }
  },
  methods: {
    startDownload() {
      this.isLoading = true
      this.fetchReportData()
    },
    fetchReportData() {
      return this.reportDataService.export(
          this.$route.params.sku,
          {...this.filter, ...{file_name: this.fileName}, ...{exportAll: this.isExportAll}, ...{exportWithImage: this.isExportWithImage}},
      ).then(res => {
        this.downloadFileFromResponse(res)
        this.finishDownload();
      }).catch(error => {
        this.finishDownload();
      })
    },
    downloadFileFromResponse(response) {
      const url = URL.createObjectURL(new Blob([response.data], {
        type: response.headers['content-type']
      }))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', this.fileName)
      document.body.appendChild(link)
      link.click()
    },
    finishDownload() {
      this.isLoading = false
    },
    exportAs(format) {
      this.exportFormat = format
      this.$swal({
        title: this.$i18n.t('reports.table.export.warning.title'),
        text: this.$i18n.t('reports.table.export.warning.message'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$i18n.t('reports.table.export.warning.confirm'),
        cancelButtonText: this.$i18n.t('reports.table.export.warning.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.startDownload()
        }
      })
    },
  },
}
</script>
